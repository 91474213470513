@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=League+Spartan:wght@100..900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

.link-t {
  text-align: left;
}
.scroll-back {
  background-color: #151515;
}

.pre-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: #181818;
  color: #edecea;
  font-family: "League Spartan";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.65px;
  text-transform: uppercase;
  text-align: center;
}

.navbar-container {
  background-color: #edecea;
  padding: 50px;
  padding-bottom: 40px;
  padding-top: 40px;

  color: #181818 !important;
  font-family: "League Spartan", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-navlink {
  color: #181818;
  cursor: pointer;
  position: relative;
}
.navbar-navlink:hover {
  transform: scale(1.05);
}

.navbar-middle {
  display: flex;
  gap: 80px;
}

.instagram_logo {
  width: 36px;
  margin: 0;
  padding: 0;
  display: flex;
}

.burger-menu-button {
  display: none;
}

.navbar-rigth {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
}

.contact-button {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
}

a {
  text-decoration: none !important;
}
.instagram_logo:hover {
  transform: scale(1.2);
}
.mgm-logo {
  width: 118px;
}

@media only screen and (max-width: 820px) {
  .mgm-logo {
    width: 101px;
  }

  .navbar-container {
    background-color: #edecea;
    padding: 30px;
    padding-bottom: 40px;
    padding-top: 40px;
  
    color: #181818 !important;
    font-family: "League Spartan", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
  }
  .instagram_logo {
    display: none;
  }
  .instagram_logo_2 {
    display: flex;
    justify-content: end;
   margin-top: -20px;
    width: 40px;
  }
  .insta-container {
    display: flex;
    position: absolute;
    right: 20px;
    z-index: 9999;
    justify-content: end;
    margin-top: -40px;
  }
  .navbar-navlink {
    text-align: left;
  }
  #web {
    display: none;
  }
  .navbar-middle {
    display: none;
  }
  .contact-button {
    display: none;
  }
  .burger-menu-button {
    display: flex;
    background-color: transparent;
    border: none;
    width: 50px;
  }
  .burger-menu {
    position: absolute;
    width: 100%;
    right: 10px;
    top: 45px;
  }

  .burger-img {
    width: 50px;
  }

  .burger-container {
    display: flex;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 12px;
    flex-direction: column;
    gap: 20px;
    background-color: #edecea;
    width: 72%;
    color: white !important;
    position: absolute;
    right: 0;
    top: 120px;
    margin-left: 12px;
    margin-right: 12px;
    z-index: 9999;
    font-family: "League Spartan";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    border: 4px solid #181818;
  }
  .scroll-back {
    border-radius: 0;
  }
}
