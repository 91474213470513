/* Agrega un encabezado con el texto de la captura */
@import url("https://fo-ts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=League+Spartan:wght@100..900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");



  .form-container-all{background-color: #181818;
padding-top: 50px; display: flex;flex-wrap: nowrap;
padding-left: 70px;padding-right: 70px;padding-bottom: 50px;}
  
  /* Contenedor principal del formulario */
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    margin: 0 auto;
    background-color: #222222;
    border-radius: 20px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    color:#ECEDEA;
    width: 100%;
  }
  .left-form{width: 56%;}
  /* Contenedor de cada grupo de inputs */
  .input-container-mail {
    width: 100%;
    margin-bottom: 20px;
  }
  
  /* Etiquetas (labels) */
  .form-label {
    display: block;
    margin-bottom: 8px;
    font-size: 15px;
    color: #ECEDEA;
    font-weight: 400;
    font-family: "Poppins";
  }
  
  /* Inputs de correo y texto */
  .input-mail,
  .input-mail-big {
    width: 100%;
    padding: 10px;
    min-width: 540px;
    font-size: 16px;
    color: #fff;
    border: 0;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #313131;
    height: 59px;

  }
  
  .input-mail-big {
    height: 100px;
    resize: vertical;
    line-height: normal;
    text-align: left;
  vertical-align: top;
  }
  
  /* Botón de envío */
  .button-email {
    background-color: #181818;
    color: #ECEDEA;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
    width: 320px;
    height: 70px;
  }

  .button-email:disabled {
    background-color: #181818 !important;
    color: #ecedea3a !important;
    padding: 12px 20px;
    border: none;
    width: 320px;
    height: 70px;
  }
  
  .button-email:hover:enabled {
    background-color: #F58220;
  }
  
  .button-email:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Spinner de carga */
  .spinner {
    width: 24px;
    height: 24px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top-color: #ff6200;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }

  .form-header{font-family: "Poppins";
font-size: 69px;
font-weight: 600;color: #FFFFFF;
margin: 0;
width: 100%;

}

.input-mail-big::-webkit-input-placeholder {
    font-family: "Poppins";
font-size: 14px;
font-weight: 300;color: #6E6E6E;
    opacity: 1;
  }
  .input-mail-big:-ms-input-placeholder {
    font-family: "Poppins";
font-size: 14px;
font-weight: 300;color: #6E6E6E;
    opacity: 1;
  }
  .input-mail-big::-ms-input-placeholder {
    font-family: "Poppins";
font-size: 14px;
font-weight: 300;color: #6E6E6E;
    opacity: 1;
  }
  .input-mail-big::-moz-placeholder {
    font-family: "Poppins";
font-size: 14px;
font-weight: 300;color: #6E6E6E;
    opacity: 1;
  }




  .input-mail::-webkit-input-placeholder {
    font-family: "Poppins";
font-size: 14px;
font-weight: 300;color: #6E6E6E;
    opacity: 1;
  }
  .input-mail:-ms-input-placeholder {
    font-family: "Poppins";
font-size: 14px;
font-weight: 300;color: #6E6E6E;
    opacity: 1;
  }
  .input-mail::-ms-input-placeholder {
    font-family: "Poppins";
font-size: 14px;
font-weight: 300;color: #6E6E6E;
    opacity: 1;
  }
  .input-mail::-moz-placeholder {
    font-family: "Poppins";
font-size: 14px;
font-weight: 300;color: #6E6E6E;
    opacity: 1;
  }

  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }



  /* Versión MOBILE */
/* Tu CSS original aquí */
/* … (todo tu código sin cambios) … */


/* Versión MOBILE */
@media (max-width: 768px) {
    /* Ajusta el contenedor general */
    .form-container-all {
      padding: 20px;
      flex-wrap: wrap;
      gap:40px;
      width: 100%;
    }
    
    .form-container {
      padding: 20px;
      border-radius: 10px;
      width: 80vw;
    }
    
    .left-form {
      width: 100%;
    }
    
    /* Reduce el espacio entre inputs */
    .input-container-mail {
      margin-bottom: 15px;
    }
    
    /* Etiquetas en tamaño móvil */
    .form-label {
      font-size: 14px;
      margin-bottom: 6px;
    }
    
    /* Inputs: quita el min-width fijo y ajusta padding y altura */
    .input-mail,
    .input-mail-big {
      min-width: initial; /* Quita el 540px para permitir que se adapte */
      width: 100%;
      padding: 8px;
      font-size: 14px;
      height: 45px;
    }
    
    .input-mail-big {
      height: 80px; /* Ajusta la altura para textarea grande */
    }
    
    /* Botón de envío en mobile */
    .button-email {
      width: 100%;
      height: 60px;
      padding: 10px;
      font-size: 18px;
      border-radius: 6px;
    }
    
    /* Encabezado (form-header) */
    .form-header {
      font-size: 36px;
      text-align: start;
    }
    
    /* Ajustes del placeholder pueden mantenerse o reducirse igual */
    .input-mail::placeholder,
    .input-mail-big::placeholder {
      font-size: 14px;
    }
  }