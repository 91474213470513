/* Footer.css */

/* Contenedor principal con fondo oscuro */
.footer {
  background-color: #181818;
  color: #ECEDEA;
  padding: 60px 20px;
  font-family: Arial, sans-serif;
  font-size: 0.9rem;
}

/* Estilos para enlaces */
.footer a {
  color: #ECEDEA;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #ffffff;
}

/* Contenedor central que centra el contenido y limita el ancho */
.footer-container {
  width: 92%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  
}

/* Distribución de columnas en la parte superior */
.footer-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  font-family: "Poppins";
}

/* Cada columna */
.footer-column {
  flex: 1;
  min-width: 200px;
}

/* Títulos de columna */
.footer-column h3 {
  margin-bottom: 10px;
  font-size: 1.1rem;
  text-transform: uppercase;
}

/* Listas dentro de cada columna */
.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
  
}

.footer-column li {
  margin-bottom: 12px;
}

/* Sección inferior con borde superior */
.footer-bottom {
  text-align: right;
  border-top: 1px solid rgba(236, 237, 234, 0.2);
  padding-top: 20px;
}

/* Responsive: en pantallas por debajo de 768px se apilan las columnas */
@media (max-width: 768px) {
  .footer-columns {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    
  }
  .footer-column{width: 100% !important;
    min-width: 400px !important;}
  .footer-bottom {
    text-align: center;
  }
}
